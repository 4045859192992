.footer{
    background-color: rgb(60, 60, 60);
    height: 45px;
}

.copyright-text {
    display: flex;
    justify-content: center;
    font-size: 1.6vh;
}

.copyright-container {
    margin-top: 200px;
}