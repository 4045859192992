video {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
  
}

.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.748);
  object-fit: contain;
  overflow-x: hidden;
}

.name {
  color: #fff;
  font-size: 130px;
  font-family: 'PT Sans', sans-serif;
  display: flex;
  align-items:center;
  justify-content: center;
}
.hero-container > h2 {
  color: #fff;
}

.hero-container > p {
  margin-top: 0px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  margin-bottom: 20px;
}

.fa-github {
  color: #fff;
  font-size: 2.5rem;
}

.fa-linkedin{
  color: #fff;
  font-size: 2.5rem;
}

.fa-github:hover {
  font-size: 2.65rem;
  transition: all 0.2s ease-in-out;
}

.fa-linkedin:hover {
  font-size: 2.65rem;
  transition: all 0.2s ease-in-out;
}

.website-logos a {
  margin-right: 100px;
  margin-left: 100px;
}

@media screen and (max-width: 960px) {
  .name {
    font-size: 100px;
    margin-top: -100px;
  }

  .hero-container > p {
    margin-top: 0px;
    font-size: 20px;
    margin-bottom: 17px;
  }

  .fa-linkedin {
    font-size: 1.75rem;
  }

  .fa-github {
    font-size: 1.75rem;
  }

  .fa-github:hover {
    font-size: 1.9rem;
    transition: all 0.2s ease-in-out;
  }

  .fa-linkedin:hover {
    font-size: 1.9rem;
    transition: all 0.2s ease-in-out;
  }

  .website-logos a {
    margin-right: 50px;
    margin-left: 50px;
  }

}

@media screen and (max-width: 768px) {
  .name{
    font-size: 70px;
    margin-top: -100px;
  }

  .hero-container > p {
    margin-top: 5px;
    font-size: 15px;
    margin-bottom: 17px;
  }

  .fa-linkedin {
    font-size: 1.4rem;
  }

  .fa-github {
    font-size: 1.4rem;
  }

  .fa-github:hover {
    font-size: 1.5rem;
    transition: all 0.2s ease-in-out;
  }

  .fa-linkedin:hover {
    font-size: 1.5rem;
    transition: all 0.2s ease-in-out;
  }
  
  .website-logos a {
    margin-right: 30px;
    margin-left: 30px;
  }
}

@media screen and (max-width: 390px) {
  .name {
    font-size:65px;
  }
}