


.card {
    width: 350px;          /* Define the size of the square card */
    height: 250px;
    overflow: hidden;
    border-radius: 10px;
    border: 3px solid rgb(16, 16, 16);
    position:relative;
    background-color: white;
}


#img1 {
    margin-top: 0px;
    max-width: 100%;
    max-height: 100%;
    margin-left: -8px;
 }
 
 #img2 {
    margin-top: 0px;
    margin-left: 0px;
    max-width: 100%;
 }

 #img3 {
    margin-top: 75px;
    max-width: 100%;
    max-height: 100%;
 }

 #img4 {
    max-width: 100%;
    max-height: 100%;
    margin-left: 50px;
 } 

 #img5 {
    width: 250px;
    margin-left: 43px;
 } 

.card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.731);
}

.card:hover .card-overlay {
    opacity: 1;
}

.card-overlay h3 {
    font-size: 35px;
    color:white;
}


.card-overlay p {
    color: white;
    text-align: center;
    margin-bottom: 25px;
    margin-left: 3px;
}

.card-overlay h4 {
    font-size: 15px;
    color: white;
    margin-top: 10px;
    margin-left: 3px;
    

}

#third-github{
    font-size: 50px;
}