
.button-28 {
  background-color: transparent;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  line-height: normal;
  padding: 16px 24px;
  text-align: center;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  touch-action: manipulation;
  width: 300px;
  margin-top: 60px;
  margin-bottom: 50px;
}

.button-28:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

@media screen and (max-width:768px) {

    .button-28 {
        width: 250px;
        margin-bottom: 40px;

      }
}